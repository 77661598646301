<template>
  <v-container>
    <v-row class="pa-3">
      <v-col cols="12" :loading="loading">
        <v-sheet class="title text-center pa-5 elevation-2 rounded">
          <h1 class="text-h6 mb-5">Booth Assignment</h1>
          <v-row
            align="center"
            v-for="i in 4"
            :key="i"
            justify="center"
            class="d-flex flex-wrap"
            style="min-height: 50px"
          >
            <v-card
              v-for="booth in booths[i - 1]"
              :key="booth"
              class="mx-auto my-3 pa-1"
              width="250"
              elevation="4"
            >
              <v-card-text class="pa-2 text-center"
                >Booth : {{ booth }}</v-card-text
              >

              <v-card-actions>
                <div class="d-flex align-start">
                  <v-autocomplete
                    v-model="booth_data[booth]"
                    outlined
                    dense
                    label="Vendor"
                    :items="vendors"
                    item-text="company_name"
                    item-value="vendor_id"
                    :error-messages="form_error.room_id"
                    @change="assignBooth($event, booth)"
                  ></v-autocomplete>
                  <v-btn
                    :disabled="!booth_data[booth]"
                    icon
                    @click="deleteBooth(booth_data[booth], booth)"
                    ><v-icon color="red">mdi mdi-delete-outline</v-icon></v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-btn small color="secondary" fixed fab bottom left to="/rooms">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      selected: "",
      form_data: {},
      form_error: {},
      vendors: [],
      booth_data: {},
      room_detail: {},
      booths: [["A1", "A2", "A3"]],
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getVendorList() {
      let _self = this;
      this.$axios
        .get("/admin/vendor/list?pagination=false")
        .then((response) => {
          _self.vendors = response.data.data;
        })
        .catch(function () {});
    },
    assignBooth(vendor_id, booth_id) {
      let _self = this;
      let formData = {};
      formData.vendor_id = vendor_id;
      formData.booth_id = booth_id;
      formData.room_id = _self.$route.params.room_id;
      this.$axios
        .post(`admin/booth/assign`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              position: "top",
              variant: "success",
              message: response_data.message,
            });
          } else {
            let errors = response_data.error;
            _self.setAlert({
              show: true,
              variant: "red darken-4",
              message: errors.message,
              dismissCountDown: 5000,
            });
          }
          _self.getRoomDetails();
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    deleteBooth(vendor_id, booth_id) {
      console.log(vendor_id, booth_id, ",,,,,,,,,,,,,,,,,,,,");
      let _self = this;
      let formData = {};
      formData.vendor_id = vendor_id;
      formData.booth_id = booth_id;
      formData.room_id = _self.$route.params.room_id;
      this.$axios
        .post(`admin/booth/delete`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              position: "top",
              variant: "success",
              message: response_data.message,
            });
          } else {
            let errors = response_data.error;
            _self.setAlert({
              show: true,
              variant: "red darken-4",
              message: errors.message,
              dismissCountDown: 5000,
            });
          }
          _self.getRoomDetails();
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getRoomDetails() {
      let _self = this;
      this.loading = "accent";
      this.$axios
        .get("/admin/room/details?room_id=" + _self.$route.params.room_id)
        .then((response) => {
          let data = response.data.data;
          _self.room_detail = response.data.room_detail;
          _self.booth_data = {};
          for (let key in data) {
            let booth_key = data[key].booth_id;
            _self.booth_data[booth_key] = data[key].vendor_id;
          }
        })
        .catch(function () {})
        .then(() => {
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getVendorList();
    this.getRoomDetails();
  },
};
</script>
